import * as React from 'react'
import styled from 'styled-components'
import { useEvent, useStore } from 'effector-react/ssr'
import { useEffect, useRef } from 'react'
import { Layout } from '@ui/layout'
import { Devices } from '@styles'
import * as model from './model'
import bg320 from './bg-320.png'
import bg1200 from './bg-1200.png'
import LeftIcon from './left.svg'
import RightIcon from './right.svg'

export const PhotoGalleryPage = () => {
  const photos = useStore(model.$photos)
  const selectedPhoto = useStore(model.$selectedPhoto)

  const prev = useEvent(model.prev)
  const next = useEvent(model.next)
  const selectPhoto = useEvent(model.selectPhoto)
  const resetPhoto = useEvent(model.resetSelectedPhoto)

  const thumbnailsWrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => resetPhoto, [])

  const nextPhoto = () => {
    next()
  }

  const prevPhoto = () => {
    prev()
  }

  const rightRoll = () => {
    if (!thumbnailsWrapperRef.current) {
      return
    }

    if (thumbnailsWrapperRef.current!.scrollLeft + 100 > thumbnailsWrapperRef.current!.scrollWidth) {
      thumbnailsWrapperRef.current!.scrollLeft = thumbnailsWrapperRef.current!.scrollWidth
      return
    }

    thumbnailsWrapperRef.current!.scrollLeft += 100
  }

  const leftRoll = () => {
    if (!thumbnailsWrapperRef.current) {
      return
    }

    if (thumbnailsWrapperRef.current!.scrollLeft - 100 <= 0) {
      thumbnailsWrapperRef.current!.scrollLeft = 0
      return
    }

    thumbnailsWrapperRef.current!.scrollLeft -= 100
  }

  return (
    <GalleryLayout showBorder={false}>
      <GalleryWrapper>
        <LeftIconWrapper onClick={prevPhoto}>
          <LeftIcon />
        </LeftIconWrapper>

        <RightIconWrapper onClick={nextPhoto}>
          <RightIcon />
        </RightIconWrapper>

        <MainPhoto style={{ backgroundImage: `url(${selectedPhoto?.photo.url})` }} />

        <LeftRollWrapper onClick={leftRoll}>
          <LeftIcon />
        </LeftRollWrapper>

        <Thumbnails ref={thumbnailsWrapperRef}>
          {photos.map((p, index) => (
            <ThumbnailWrapper key={p.id} onClick={() => selectPhoto(index)}>
              <Thumbnail src={p.photo.formats.thumbnail.url} alt='' />
              {p.id === selectedPhoto?.id && <ThumbnailBorder />}
            </ThumbnailWrapper>
          ))}
        </Thumbnails>

        <RightRollWrapper onClick={rightRoll}>
          <RightIcon />
        </RightRollWrapper>
      </GalleryWrapper>
    </GalleryLayout>
  )
}

const GalleryLayout = styled(Layout)`
  background-image: url('${bg320}');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;

  @media screen and ${Devices.laptop} {
    background-image: url('${bg1200}');
  }
`

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;

  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
`

const LeftIconWrapper = styled.div`
  cursor: pointer;

  position: absolute;
  left: 15px;
  top: 50%;
  width: 30px;

  display: flex;
  justify-content: center;
  align-content: center;
`

const RightIconWrapper = styled.div`
  cursor: pointer;

  position: absolute;
  right: 15px;
  top: 50%;
  width: 30px;

  display: flex;
  justify-content: center;
  align-content: center;
`

const LeftRollWrapper = styled.div`
  display: none;

  @media screen and ${Devices.laptop} {
    cursor: pointer;

    position: absolute;
    left: calc(50% - 450px);
    bottom: 60px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-content: center;
  }
`

const RightRollWrapper = styled.div`
  display: none;

  @media screen and ${Devices.laptop} {
    cursor: pointer;

    position: absolute;
    right: calc(50% - 450px);
    bottom: 60px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-content: center;
  }
`

const MainPhoto = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 50%;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and ${Devices.laptop} {
    width: 800px;
  }

  @media screen and ${Devices.desktopS} {
    width: 900px;
  }

  @media screen and ${Devices.desktopM} {
    width: 1660px;
  }
`

const Thumbnails = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 120px;
  overflow: auto;
  padding: 0 10px;
  user-select: none;

  @media screen and ${Devices.laptop} {
    padding: unset;
    width: 800px;
    left: calc(50% - 400px);
    bottom: 0;
    height: 130px;
    overflow: hidden;
  }
`

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
`

const Thumbnail = styled.img`
  width: auto;
  height: 90px;

  @media screen and ${Devices.laptop} {
    height: 105px;
  }
`

const ThumbnailBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 5px;
  top: 100px;
  background-color: #e469d1;

  @media screen and ${Devices.laptop} {
    top: 115px;
  }
`
