import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Layout } from '@ui/layout'
import { Devices } from '@styles'
import { paths } from '@pages/paths'
import bg320 from './bg-320.png'
import bg1200 from './bg-1200.png'
import photo1320 from './photo-1-320.png'
import photo11200 from './photo-1-1200.png'
import photo2320 from './photo-2-320.png'
import photo21200 from './photo-2-1200.png'

export const PhotoPage = () => {
  return (
    <Layout>
      <LinksWrapper>
        <ImageLink to={`${paths.photoGallery()}?album=1`} style={{ top: 0, zIndex: 1 }}>
          <picture>
            <source media={Devices.laptop} srcSet={photo11200} />
            <source media={Devices.mobileS} srcSet={photo1320} />
            <img src={photo1320} alt='' />
          </picture>
        </ImageLink>

        <ImageLink to={`${paths.photoGallery()}?album=2`} style={{ bottom: 0, zIndex: 2 }}>
          <picture>
            <PhotoLabel>Студийные фото</PhotoLabel>
            <Photo2Label>Концертные фото</Photo2Label>
            <source media={Devices.laptop} srcSet={photo21200} />
            <source media={Devices.mobileS} srcSet={photo2320} />
            <img src={photo2320} alt='' />
          </picture>
        </ImageLink>
      </LinksWrapper>
    </Layout>
  )
}

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 100%;

  position: absolute;
  height: calc(100vh - 70px);
  background-image: url('${bg320}');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 0;
  overflow: hidden;

  @media screen and ${Devices.tablet} {
    height: calc(100vh - 80px);
    background-image: url('${bg1200}');
  }

  @media screen and ${Devices.laptop} {
    height: calc(100vh - 80px);
    background-image: url('${bg1200}');
  }

  @media screen and ${Devices.desktopM} {
    height: calc(100vh - 180px);
  }
`

const ImageLink = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;

  position: absolute;
  left: 0;

  picture,
  img {
    display: flex;
    width: 100%;
    position: relative;
  }
`

const LinkLabel = styled.p`
  color: white;

  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  font-family: 'Magnolia Script';
  margin: 30px 25px;

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
`

const PhotoLabel = styled(LinkLabel)`
  bottom: 100%;
  left: 0;
`

const Photo2Label = styled(LinkLabel)`
  top: 0;
  left: 0;

  @media screen and ${Devices.tablet} {
    bottom: 0;
  }
`
