import React from 'react'
import styled from 'styled-components'
import { Devices } from '@styles'
import SocialFacebook from './facebook.svg'
import SocialInstagram from './instagram.svg'
import SocialOk from './ok.svg'
import SocialVk from './vk.svg'
import SocialYoutube from './youtube.svg'
import SocialTelegram from './telegram.svg'
import LineIcon from './line.svg'

export const Social = () => (
  <List>
    <Item>
      <Link href='https://ok.ru/group/68622723514413' target='_blank'>
        {/*@ts-ignore*/}
        <SocialOk />
      </Link>
    </Item>
    <Item>
      <Link href='https://t.me/chistovamusic' target='_blank'>
        {/*@ts-ignore*/}
        <SocialTelegram />
      </Link>
    </Item>
    <Item>
      <Link href='https://vk.com/chistovagroup' target='_blank'>
        {/*@ts-ignore*/}
        <SocialVk />
      </Link>
    </Item>
    <Item>
      <Link href='https://www.youtube.com/c/Chistovamusic' target='_blank'>
        {/*@ts-ignore*/}
        <SocialYoutube />
      </Link>
    </Item>
    <Item>
      <LineIcon />
    </Item>
  </List>
)

const List = styled.ul`
  display: none;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 6.5%;
  left: 6.7%;
  z-index: 1000000;

  padding: 0;
  margin: 0;
  list-style: none;

  @media screen and ${Devices.laptop} {
    display: flex;
  }

  > li:not(:last-child) {
    margin-left: -10px;
  }
`

const Item = styled.li`
  display: flex;
  font-size: 24px;
  color: #333333;
  text-transform: uppercase;
  margin-left: -5px;

  &:last-child {
    margin-top: 0;

    svg {
      height: 50px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;

    svg {
      width: 10px;
      height: 100%;
    }
  }

  @media screen and ${Devices.desktopS} {
    &:last-child {
      svg {
        height: unset;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;

      svg {
        width: 15px;
      }
    }
  }

  @media screen and ${Devices.desktopM} {
    &:not(:last-child) {
      margin-bottom: 30px;

      svg {
        width: 20px;
      }
    }
  }
`

const Link = styled.a`
  color: unset;
  text-decoration: none;
`
