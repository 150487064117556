import React from 'react'
import styled from 'styled-components'
import { paths } from '@pages/paths'
import { Devices } from '@styles'
import { NavLink } from '@ui/link'

export const Navigation = () => (
  <List>
    <Link href={paths.about()}>Обо мне</Link>
    <Link href={paths.music()}>Музыка</Link>
    <Link href={paths.photoVideo()}>Фото/видео</Link>
    <Link href={paths.news()}>Новости</Link>
    <Link href={paths.live()}>LIVE</Link>
    {/*<Link href={paths.store()}>Store</Link>*/}
    <Link href={paths.contacts()}>Контакты</Link>
  </List>
)

const List = styled.nav`
  display: grid;
  grid-auto-rows: max-content;

  font-family: 'Magnolia Script', 'SansSerif';

  @media screen and ${Devices.desktopM} {
    padding-left: 85px;
  }
`

const Link = styled(NavLink)`
  a {
    color: white;
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 15px;

    @media screen and ${Devices.desktopS} {
      font-size: 28px;
      line-height: 40px;
    }
  }

  svg {
    bottom: 10px;
  }
`
