import * as React from 'react'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useEvent, useStore } from 'effector-react/ssr'
import loadable from '@loadable/component'
import { Layout } from '@ui/layout'
import { Devices } from '@styles'
import { $isMobile } from '@features/window-size-observer'
import * as model from './model'
import bg320 from './bg-320.png'
import bg1200 from './bg-1200.png'
import Left from './left.svg'
import Right from './right.svg'
import styles from './styled.module.scss'

const DesktopPlayer = loadable(() => import('@features/video-player/desktop-player'), {
  resolveComponent: (module) => module.DesktopVideoPlayer,
  ssr: false,
})

const MobilePlayer = loadable(() => import('@features/video-player/mobile-player'), {
  resolveComponent: (module) => module.MobileVideoPlayer,
  ssr: false,
})

export const VideoGalleryPage = () => {
  const isMobile = useStore($isMobile)

  const videos = useStore(model.$videos)
  const selectedVideo = useStore(model.$selectedVideo)

  const prev = useEvent(model.prev)
  const next = useEvent(model.next)
  const selectVideo = useEvent(model.selectVideo)
  const onUnmount = useEvent(model.resetSelectedVideo)

  const thumbnailsWrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => onUnmount, [])

  console.log({ selectedVideo })

  const nextVideo = () => {
    console.log('nextVideo')
    next()
  }

  const prevVideo = () => {
    console.log('prevVideo')
    prev()
  }

  const rightRoll = () => {
    if (!thumbnailsWrapperRef.current) {
      return
    }

    if (thumbnailsWrapperRef.current!.scrollLeft + 100 > thumbnailsWrapperRef.current!.scrollWidth) {
      thumbnailsWrapperRef.current!.scrollLeft = thumbnailsWrapperRef.current!.scrollWidth
      return
    }

    thumbnailsWrapperRef.current!.scrollLeft += 100
  }

  const leftRoll = () => {
    if (!thumbnailsWrapperRef.current) {
      return
    }

    if (thumbnailsWrapperRef.current!.scrollLeft - 100 <= 0) {
      thumbnailsWrapperRef.current!.scrollLeft = 0
      return
    }

    thumbnailsWrapperRef.current!.scrollLeft -= 100
  }

  return (
    <GalleryLayout whiteNavigationFont showBorder={false}>
      <GalleryWrapper>
        <LeftIconWrapper onClick={prevVideo}>
          <LeftIcon />
        </LeftIconWrapper>

        <RightIconWrapper onClick={nextVideo}>
          <RightIcon />
        </RightIconWrapper>

        <Description>
          <DescriptionText>{selectedVideo?.title}</DescriptionText>
          <DescriptionText>{selectedVideo?.description}</DescriptionText>
        </Description>

        <div className={styles.playerWrapper}>
          {selectedVideo !== null && !isMobile && <DesktopPlayer source={selectedVideo} onUnmount={() => {}} />}
          {isMobile && <MobilePlayer source={selectedVideo} onUnmount={() => {}} />}
        </div>

        <LeftRollWrapper onClick={leftRoll}>
          <LeftIcon />
        </LeftRollWrapper>

        <Thumbnails ref={thumbnailsWrapperRef}>
          {videos.map((p, index) => (
            <ThumbnailWrapper key={p.id} onClick={() => selectVideo(index)}>
              <VideoThumbnail url={p.url} />
              {p.id === selectedVideo?.id && <ThumbnailBorder />}
            </ThumbnailWrapper>
          ))}
        </Thumbnails>

        <RightRollWrapper onClick={rightRoll}>
          <RightIcon />
        </RightRollWrapper>
      </GalleryWrapper>
    </GalleryLayout>
  )
}

function youtubeParser(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

interface VideoPreviewProps {
  url: string
}

const VideoThumbnail = (props: VideoPreviewProps) => {
  const videoId = youtubeParser(props.url)
  const previewUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`

  return <Thumbnail src={previewUrl} alt='' />
}

const GalleryLayout = styled(Layout)`
  background-image: url('${bg320}');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;

  @media screen and ${Devices.laptop} {
    background-image: url('${bg1200}');
  }
`

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;

  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
`

const LeftIcon = styled(Left)`
  path {
    fill: #333333 !important;
  }

  @media screen and ${Devices.laptop} {
    path {
      fill: white !important;
    }
  }
`

const RightIcon = styled(Right)`
  path {
    fill: #333333 !important;
  }

  @media screen and ${Devices.laptop} {
    path {
      fill: white !important;
    }
  }
`

const LeftIconWrapper = styled.div`
  cursor: pointer;

  position: absolute;
  left: 15px;
  top: 50%;
  z-index: 1000;
  width: 30px;

  display: flex;
  justify-content: center;
  align-content: center;
`

const RightIconWrapper = styled.div`
  cursor: pointer;

  position: absolute;
  right: 15px;
  top: 50%;
  z-index: 1000;
  width: 30px;

  display: flex;
  justify-content: center;
  align-content: center;
`

const LeftRollWrapper = styled.div`
  display: none;

  @media screen and ${Devices.laptop} {
    cursor: pointer;

    position: absolute;
    left: calc(50% - 500px);
    bottom: 100px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-content: center;
  }
`

const RightRollWrapper = styled.div`
  display: none;

  @media screen and ${Devices.laptop} {
    cursor: pointer;

    position: absolute;
    right: calc(50% - 500px);
    bottom: 100px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-content: center;
  }
`

const Thumbnails = styled.div`
  display: flex;
  position: absolute;
  bottom: 30px;

  width: 100%;
  height: 120px;
  overflow: auto;
  padding: 0 10px;
  user-select: none;

  @media screen and ${Devices.laptop} {
    padding: unset;
    width: 1000px;
    left: calc(50% - 450px);
    height: 180px;
    overflow: hidden;
  }
`

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;

  @media screen and ${Devices.laptop} {
    margin: 10px;
  }
`

const Thumbnail = styled.img`
  width: auto;
  height: 90px;

  @media screen and ${Devices.laptop} {
    cursor: pointer;
    height: 152px;
  }
`

const ThumbnailBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 5px;
  top: 100px;
  background-color: #e469d1;

  @media screen and ${Devices.laptop} {
    top: 160px;
  }
`

const Description = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 999;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and ${Devices.laptop} {
    display: none;
  }
`

const DescriptionText = styled.p`
  margin: 0;
  color: #333333;
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
