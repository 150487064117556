import * as React from 'react'
import styled from 'styled-components'
import { Event } from 'effector'
import { useEvent, useStore } from 'effector-react/ssr'
import { StartParams, useStart, withStart } from '@lib/page-routing'
import { PlayIcon } from '@ui/icons/player'
import { LogoIcon } from '@ui/icons/logo'
import * as model from '@pages/home/model'
import { Navigation } from '@pages/home/navigation'
import { Devices } from '@styles'
import { Slider } from '@ui/slider'
import HomePageCover from '@assets/images/home-page-cover.jpg'
import Bg1 from '@assets/images/tickets-bg1.png'
import Bg2 from '@assets/images/tickets-bg2.png'
import Bg3 from '@assets/images/tickets-bg3.png'
import HomePageLogo from '@assets/images/home-page-logo.png'
import CloseIcon from '@assets/icons/close.svg'

// import Spinner from '@assets/icons/spinner.svg'
// import BPlay from '@assets/icons/button_play.svg'

import kate_home from '@assets/images/kate_home.jpg'
import TicketsKate from '@assets/images/tickets-kate.png'
// import TicketsLogo from '@assets/images/tickets-logo.svg'
// import TicketsPlace from '@assets/images/tickets-place.svg'
// import TicketsTime from '@assets/images/tickets-time.svg'
// import TicketsTitle from '@assets/images/tickets-title.svg'
import { SliderActions } from '@api/sliders'
import { getImageUrlForDevice } from '@lib/image-media'
import { $device, $isMobile } from '@features/window-size-observer'
import { Layout } from '@ui/layout'
import { Social } from './social'
import loadable from '@loadable/component'
import { useState } from 'react'

export const HomePage = () => {
  const currentItemIndex = useStore(model.$currentItemIndex)

  const video = useStore(model.$video)
  const backgroundDesktopVideo = useStore(model.$backgroundDesktopVideo)
  const isMobile = useStore($isMobile)
  const [videoModalIsOpened, setVideoVideoModalIsOpened] = useState(false)
  // const itemsCount = useStore(model.$itemsCount)
  // const sliderItem = useStore(model.$currentItem)
  // const device = useStore($device)

  // const prevClicked = useEvent(model.prevClicked)
  // const nextClicked = useEvent(model.nextClicked)

  // const cover = sliderItem !== null ? getImageUrlForDevice(sliderItem.cover, device) : HomePageCover

  return (
    <Layout transparentMobileNav hideDesktopNav>
      <Page>
        <Social />
        {/*<Cover src={cover} alt='' />*/}

        {/*{sliderItem?.action === SliderActions.Play && (*/}
        {/*  <PlayIconWrapper>*/}
        {/*    <PlayIcon color='white' />*/}
        {/*  </PlayIconWrapper>*/}
        {/*)}*/}

        {/*{sliderItem?.action === SliderActions.Tickets && (*/}
        {/*  <TicketsLinkWrapper>*/}
        {/*    <TicketsLink target='_blank' href='https://sochi.kassir.ru/koncert/ekaterina-chistova'>*/}
        {/*      КУПИТЬ БИЛЕТЫ*/}
        {/*    </TicketsLink>*/}
        {/*  </TicketsLinkWrapper>*/}
        {/*)}*/}

        {videoModalIsOpened && video !== null && (
          <div
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 1000000,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'black',
            }}
          >
            <div
              style={{ position: 'fixed', right: 10, top: 10, zIndex: 1000001, cursor: 'pointer' }}
              onClick={() => setVideoVideoModalIsOpened(false)}
            >
              <CloseIcon />
            </div>
            <video style={{ width: '100vw', height: '100%' }} controls autoPlay>
              <source type={video.mime} src={video.url} />
            </video>
          </div>
        )}

        <BackgroundWrapper>
          {backgroundDesktopVideo !== null && (
            <BackgroundVideoWrapper>
              <BackgroundVideo muted autoPlay loop playsInline>
                <source type={backgroundDesktopVideo.mime} src={backgroundDesktopVideo.url} />
              </BackgroundVideo>
            </BackgroundVideoWrapper>
          )}

          {/* Основная картинка */}
          {/*<Background1 src={Bg1} alt='' />*/}

          {/*<Background2 src={Bg2} alt='' />*/}

          {/* Аля дымок*/}
          <Background3 src={Bg3} alt='' />

          {/*<BackgroundKateWrapper>*/}
          {/*  <BackgroundKate src={TicketsKate} alt='' />*/}
          {/*</BackgroundKateWrapper>*/}

          {/* Катя Чистова */}
          {/*<BackgroundLogoWrapper>*/}
          {/*  <BackgroundLogo />*/}
          {/*</BackgroundLogoWrapper>*/}

          {/*<TicketsPlaceWrapper>*/}
          {/*  <Place />*/}
          {/*</TicketsPlaceWrapper>*/}

          {/*<TicketsTimeWrapper>*/}
          {/*  <Time />*/}
          {/*</TicketsTimeWrapper>*/}

          {/*<TicketsTitleWrapper>*/}
          {/*  <Title />*/}
          {/*</TicketsTitleWrapper>*/}

          {/*<HomePageLogoWrapper>*/}
          {/*  <HomePageLogoImage src={HomePageLogo} alt='' />*/}
          {/*</HomePageLogoWrapper>*/}

          {/*<MobilePlayButtonWrapper>*/}
          {/*  <div onClick={() => setVideoVideoModalIsOpened(true)}>*/}
          {/*    <Spinner />*/}
          {/*  </div>*/}
          {/*</MobilePlayButtonWrapper>*/}

          {/*<TicketsLinkWrapper>*/}
          {/*  <TicketsLink target='_blank' href='https://taplink.cc/chistovamusic'>*/}
          {/*    КУПИТЬ БИЛЕТЫ*/}
          {/*  </TicketsLink>*/}
          {/*</TicketsLinkWrapper>*/}

          {/*<DesktopPlayButtonWrapper onClick={() => setVideoVideoModalIsOpened(true)}>*/}
          {/*  <Spinner />*/}
          {/*</DesktopPlayButtonWrapper>*/}
        </BackgroundWrapper>

        <RightContent>
          <LogoWrapper>
            <LogoIcon color='white' />
          </LogoWrapper>

          <NavigationWrapper>
            <Navigation />
          </NavigationWrapper>

          {/*{itemsCount > 1 && (*/}
          {/*  <Slider*/}
          {/*    currentItemIndex={currentItemIndex}*/}
          {/*    itemsCount={itemsCount}*/}
          {/*    prevClicked={prevClicked}*/}
          {/*    nextClicked={nextClicked}*/}
          {/*  />*/}
          {/*)}*/}
        </RightContent>
      </Page>
    </Layout>
  )
}

const BackgroundLogo = loadable(() => import('./logo-image'), {
  resolveComponent: (module) => module.BackgroundLogo,
  ssr: false,
})

const Place = loadable(() => import('./place-image'), { resolveComponent: (module) => module.Place, ssr: false })

const Title = loadable(() => import('./title-image'), { resolveComponent: (module) => module.Title, ssr: false })

const Time = loadable(() => import('./time-image'), { resolveComponent: (module) => module.Time, ssr: false })

const Spinner = loadable(() => import('./spinner-image'), { resolveComponent: (module) => module.Spinner, ssr: false })

const Play = loadable(() => import('./play-image'), { resolveComponent: (module) => module.Play, ssr: false })

const MobilePlayer = loadable(() => import('@features/video-player/mobile-player'), {
  resolveComponent: (module) => module.MobileVideoPlayer,
  ssr: false,
})

const Page = styled.div`
  @media screen and ${Devices.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr;

    font-family: 'Raleway', 'SansSerif';
    padding: 40px 134px;
  }
`

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const Background1 = styled.img`
  display: flex;

  position: fixed;
  z-index: 2;

  width: 100vw;
  height: 100vh;
`

const Background2 = styled.img`
  position: fixed;
  z-index: 3;

  width: 100vw;

  bottom: 14vh;

  @media screen and (orientation: landscape) {
    width: 45vw;
    max-width: 331px;

    left: 28vw;
    bottom: 0;
  }

  @media screen and ${Devices.laptop} {
    bottom: 0vh;
    width: 50vw;
    left: 13vw;

    max-width: unset;
  }

  @media screen and ${Devices.desktopS} {
    bottom: unset;
    width: 40vw;
    left: 15vw;
  }

  @media screen and ${Devices.desktopM} {
    width: 45vw;
    left: 15vw;
  }
`

const BackgroundKateWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`

const BackgroundKate = styled.img`
  position: fixed;
  z-index: 4;

  bottom: 0vh;

  height: 100vh;
  max-height: 450px;

  @media screen and (orientation: landscape) {
    max-width: 112px;
  }

  @media screen and ${Devices.mobileM} {
    max-height: 500px;
  }

  @media screen and ${Devices.mobileL} {
    max-height: 550px;
  }

  @media screen and ${Devices.laptop} {
    left: 30vw;
    bottom: 0;

    height: 88vh;
    max-height: unset;
    max-width: unset;
  }

  @media screen and ${Devices.desktopS} {
    left: 30vw;
    height: 88vh;
  }
`

const Background3 = styled.img`
  display: none;
  position: fixed;
  z-index: 5;

  height: 100vh;
  bottom: 0;

  @media screen and (orientation: landscape) {
    height: unset;
    width: 100vw;
  }

  @media screen and ${Devices.laptop} {
    display: unset;
    width: 100vw;
  }
`

const BackgroundLogoWrapper = styled.div`
  display: none;
  justify-content: center;

  position: fixed;
  z-index: 6;
  bottom: 24vh;

  width: 100vw;

  @media screen and (orientation: landscape) {
    bottom: 5vh;
  }

  @media screen and ${Devices.mobileLandscapeM} and (orientation: landscape) {
    bottom: 12vh;
  }

  @media screen and ${Devices.laptop} {
    display: flex;
    justify-content: unset;
    left: 25vw;
    bottom: 20vh;

    width: 30vw;
  }

  @media screen and ${Devices.desktopS} {
    left: 25vw;
    bottom: 20vh;

    width: 30vw;
  }

  @media screen and ${Devices.desktopM} {
    left: 20vw;
    bottom: 30vh;
    width: 45vw;
  }
`

const TicketsLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  position: fixed;
  z-index: 6;
  bottom: 38vh;

  @media screen and (orientation: landscape) {
    bottom: 5vh;
  }

  @media screen and ${Devices.laptop} {
    justify-content: unset;
    left: 25vw;
    bottom: 10vh;
  }

  @media screen and ${Devices.desktopS} {
    left: 25vw;
    //bottom: 2vh;
  }

  @media screen and ${Devices.desktopM} {
    left: 26vw;
    //bottom: 7vh;
  }

  @media screen and ${Devices.desktopL} {
    left: 29vw;
    //bottom: 7vh;
  }
`

const TicketsPlaceWrapper = styled.div`
  display: flex;
  justify-content: center;

  position: fixed;
  top: 10vh;
  z-index: 6;

  width: 100%;

  @media screen and (orientation: landscape) {
    top: 4vh;
  }

  @media screen and ${Devices.laptop} {
    display: none;

    justify-content: unset;

    top: 5vh;
    left: 5vw;
  }

  @media screen and ${Devices.desktopS} {
    top: 5vh;
    left: 5vw;
  }
`

const MobilePlayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: 6vh;
  z-index: 6;

  width: 100%;

  @media screen and (orientation: landscape) {
    bottom: 0;
  }

  @media screen and ${Devices.laptop} {
    display: none;
  }

  @media screen and ${Devices.mobileLandscapeM} and (orientation: landscape) {
    bottom: 4vh;
  }

  @media screen and ${Devices.laptop} {
    justify-content: unset;
    top: unset;
    bottom: 8vh;
    left: 22vw;
  }

  @media screen and ${Devices.desktopS} {
    bottom: 8vh;
    left: 22vw;
  }

  @media screen and ${Devices.desktopM} {
    left: 15vw;
    bottom: 15vh;
  }
`

const TicketsTitleWrapper = styled.div`
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: 16vh;
  z-index: 6;

  width: 100%;

  @media screen and (orientation: landscape) {
    bottom: 0;
  }

  @media screen and ${Devices.mobileLandscapeM} and (orientation: landscape) {
    bottom: 4vh;
  }

  @media screen and ${Devices.laptop} {
    display: none;

    justify-content: unset;
    top: unset;
    bottom: 8vh;
    left: 22vw;
  }

  @media screen and ${Devices.desktopS} {
    bottom: 8vh;
    left: 22vw;
  }

  @media screen and ${Devices.desktopM} {
    left: 15vw;
    bottom: 15vh;
  }
`

const TicketsTimeWrapper = styled.div`
  position: fixed;
  top: 13vh;
  left: 3vw;
  z-index: 6;

  width: 100%;

  @media screen and (orientation: landscape) {
    top: 5vh;
    left: 7vw;

    max-width: 73px;
  }

  @media screen and ${Devices.mobileLandscapeM} and (orientation: landscape) {
    left: 5vw;
  }

  @media screen and ${Devices.mobileM} {
    top: 14vh;
  }

  @media screen and ${Devices.laptop} {
    display: none;

    top: 16vh;
    left: 12vw;

    max-width: unset;
  }

  @media screen and ${Devices.desktopS} {
    top: 16vh;
    left: 12vw;
  }

  @media screen and ${Devices.desktopM} {
    top: 16vh;
    left: 16vw;
  }
`

const TicketsLink = styled.a`
  border: 2px solid #ffffff;
  border-radius: 68px;
  background: transparent;

  color: white;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;

  font-size: 18px;
  line-height: 15px;

  padding: 15px 35px;

  @media screen and (orientation: landscape) {
    font-size: 14px;
    padding: 10px 15px;
  }

  @media screen and ${Devices.laptop} {
    font-size: 20px;
    line-height: 29px;

    padding: 16px 56px;

    position: relative;
    right: 20px;
  }

  @media screen and ${Devices.desktopS} {
    font-size: 20px;
    line-height: 29px;

    padding: 16px 56px;

    position: unset;
    right: unset;
  }

  @media screen and ${Devices.desktopM} {
    font-size: 25px;
    line-height: 29px;

    padding: 20px 66px;
  }
`

// const Cover = styled.img`
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: -1;
//
//   min-width: 100%;
//   min-height: 100%;
// `
//
// const PlayIconWrapper = styled.div`
//   position: fixed;
//   left: 20vw;
//   bottom: 25vh;
// `

const RightContent = styled.div`
  display: none;

  @media screen and ${Devices.laptop} {
    position: fixed;
    top: 5vh;
    right: 5vw;

    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }

  @media screen and ${Devices.desktopS} {
    position: fixed;
    top: 5vh;
    right: 15vw;

    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }

  @media screen and ${Devices.desktopM} {
    padding-top: 120px;
    row-gap: 100px;

    padding-left: 280px;
  }
`

const LogoWrapper = styled.div``

const NavigationWrapper = styled.div`
  @media screen and ${Devices.laptop} {
    margin-left: 50px;
  }
`

const BackgroundVideoWrapper = styled.div`
  display: block;
`

const BackgroundVideo = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`

const DesktopPlayButtonWrapper = styled.div`
  display: none;

  @media screen and ${Devices.laptop} {
    display: block;

    position: fixed;
    left: 23vw;
    bottom: 50vh;
    z-index: 10000;

    cursor: pointer;
  }

  @media screen and ${Devices.desktopS} {
    left: 23vw;
    bottom: 50vh;
  }

  @media screen and ${Devices.desktopM} {
    left: 15vw;
    bottom: 45vh;
  }

  @media screen and ${Devices.desktopL} {
    left: 16vw;
    bottom: 45vh;
  }
`

const HomePageLogoWrapper = styled.div`
  display: none;

  @media screen and ${Devices.laptop} {
    display: block;

    position: fixed;
    left: 15vw;
    bottom: 20vh;
    z-index: 10000;

    width: 450px;
  }

  @media screen and ${Devices.desktopS} {
    left: 18vw;
    //top: 30vh;
  }

  @media screen and ${Devices.desktopM} {
    left: 22vw;
    //top: 30vh;
  }

  @media screen and ${Devices.desktopL} {
    left: 27vw;
    //top: 30vh;
  }
`

const HomePageLogoImage = styled.img`
  width: 100%;
`
