import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Layout } from '@ui/layout'
import { Devices } from '@styles'
import { paths } from '@pages/paths'
import bg320 from './bg-320.png'
import bg1200 from './bg-1200.png'
import photo320 from './photo-320.png'
import photo1200 from './photo-1200.png'
import video320 from './video-320.png'
import video1200 from './video-1200.png'

export const PhotoVideoPage = () => {
  return (
    <Layout>
      <LinksWrapper>
        <ImageLink to={paths.photo()} style={{ top: 0, zIndex: 1 }}>
          <picture>
            <source media={Devices.laptop} srcSet={photo1200} />
            <source media={Devices.mobileS} srcSet={photo320} />
            <img src={photo320} alt='' />
          </picture>
        </ImageLink>

        <ImageLink to={paths.videoGallery()} style={{ bottom: 0, zIndex: 2 }}>
          <picture>
            <PhotoLabel>Фото</PhotoLabel>
            <VideoLabel>Видео</VideoLabel>
            <source media={Devices.laptop} srcSet={video1200} />
            <source media={Devices.mobileS} srcSet={video320} />
            <img src={video320} alt='' />
          </picture>
        </ImageLink>
      </LinksWrapper>
    </Layout>
  )
}

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 100%;

  position: absolute;
  height: calc(100vh - 70px);
  background-image: url('${bg320}');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 0;
  overflow: hidden;

  @media screen and ${Devices.tablet} {
    height: calc(100vh - 80px);
    background-image: url('${bg1200}');
  }

  @media screen and ${Devices.laptop} {
    height: calc(100vh - 80px);
    background-image: url('${bg1200}');
  }

  @media screen and ${Devices.desktopM} {
    height: calc(100vh - 180px);
  }
`

const ImageLink = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;

  position: absolute;
  left: 0;

  picture,
  img {
    display: flex;
    width: 100%;
    position: relative;
  }
`

const LinkLabel = styled.p`
  color: white;
  font-weight: 400;
  font-size: 40px;
  font-family: 'Magnolia Script';
  line-height: 56px;
  margin: 30px 25px;

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
`

const PhotoLabel = styled(LinkLabel)`
  bottom: 100%;
  left: 0;
`

const VideoLabel = styled(LinkLabel)`
  top: 0;
  left: 0;

  @media screen and ${Devices.tablet} {
    bottom: 0;
  }
`
